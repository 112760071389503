import clsx from 'clsx';
import { PropsWithChildren } from 'react';

type Props = PropsWithChildren & {
  readonly className?: string;
};

export default function Container({ children, className }: Props) {
  return (
    <div className={clsx(`w-full max-w-screen-md mx-auto px-5`, className)}>
      {children}
    </div>
  );
}
