type Props = {
  readonly type?: string;
  readonly value: string;
  readonly onChange: (value: string) => void;
  readonly placeholder?: string;
};

export default function TextInput({
  value,
  onChange,
  placeholder,
  type = 'text',
}: Props) {
  return (
    <input
      value={value}
      placeholder={placeholder}
      onChange={(event) => onChange(event.target.value)}
      className="bg-transparent border border-white px-4 py-2 w-full"
      type={type}
    />
  );
}
