import {
  faArrowLeftFromBracket,
  faSquarePollHorizontal,
  faUserTieHair,
} from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx';
import { NavLink, useNavigate } from 'react-router-dom';
import { useToken } from '../hooks/useToken';

export default function Navigation() {
  const navigate = useNavigate();
  const [_, setToken] = useToken();

  const links = [
    { icon: faUserTieHair, title: 'Clients', path: '/admin/clients' },
    { icon: faSquarePollHorizontal, title: 'Surveys', path: '/admin/surveys' },
  ];

  return (
    <section className="h-full bg-ui-background text-white flex flex-col">
      <div className="p-5 flex-shrink-0 border-b border-white border-opacity-10">
        <h6 className="font-bold">Panorama° Admin</h6>
      </div>
      <nav className="flex-grow overflow-y-auto">
        {links.map((link) => (
          <div key={link.path}>
            <NavLink
              className={({ isActive }) =>
                clsx('px-5 py-2 flex items-center space-x-3', {
                  'bg-primary text-primary-dark': isActive,
                  'hover:bg-black': !isActive,
                })
              }
              to={link.path}
            >
              <span className="w-6 h-6 flex items-center justify-center opacity-75">
                <FontAwesomeIcon size="sm" icon={link.icon} />
              </span>
              <span>{link.title}</span>
            </NavLink>
          </div>
        ))}
      </nav>
      <div className="px-5 py-3 flex-shrink-0 border-t border-white border-opacity-10">
        <button
          className="text-sm flex items-center space-x-2"
          onClick={() => {
            setToken(null);
            navigate('/admin/auth');
          }}
        >
          <FontAwesomeIcon size="sm" icon={faArrowLeftFromBracket} />
          <span>Logout</span>
        </button>
      </div>
    </section>
  );
}
