import { createContext, Dispatch, SetStateAction, useContext } from 'react';
import { AnswerValue } from '../../../types';
import { Response } from './queries/getQuizQuery';

export type QuizContextType = {
  readonly quiz: Response['quiz'];
  readonly answers: Record<string, AnswerValue>;
  readonly setAnswers: Dispatch<SetStateAction<Record<string, AnswerValue>>>;
};

export const QuizContext = createContext<QuizContextType | undefined>(
  undefined,
);

export function useQuizContext() {
  const context = useContext(QuizContext);

  if (!context) {
    throw new Error('Missing quiz context.');
  }

  return context;
}
