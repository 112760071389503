import clsx from 'clsx';
import { ReactNode, useMemo } from 'react';

type Props = {
  readonly text: string;
  readonly level: 1 | 2;
  readonly className?: string;
  readonly actions?: ReactNode;
};

export default function Heading({ text, level, className, actions }: Props) {
  const tag = useMemo(() => {
    if (level === 1) {
      return <h1 className="font-light text-xl">{text}</h1>;
    }

    return <h2 className="font-light text-lg">{text}</h2>;
  }, [level, text]);

  return (
    <div className={clsx('flex items-center', className)}>
      <div className="flex-grow">{tag}</div>
      {actions && <div className="flex-shrink-0">{actions}</div>}
    </div>
  );
}
