type Props = {
  readonly type?: 'text' | 'password' | 'email';
  readonly value: string;
  readonly onChange: (value: string) => void;
  readonly placeholder?: string;
};

export default function TextInput({
  type = 'text',
  placeholder,
  value,
  onChange,
}: Props) {
  return (
    <input
      placeholder={placeholder}
      type={type}
      value={value}
      onChange={(e) => onChange(e.target.value)}
      className="bg-white border shadow-sm border-gray-400 rounded-sm px-2 py-1 w-full focus:outline-none focus:border-primary"
    />
  );
}
