import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '../../public/components/Button';
import { useToken } from '../hooks/useToken';
import { useLoginMutation } from './mutations/loginMutation';

export function AuthView() {
  const navigate = useNavigate();

  const [_, setToken] = useToken(false);

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const [login, { loading }] = useLoginMutation({
    variables: {
      email,
      password,
    },
    onError: (error) => setError(error.message),
    onCompleted: (data) => {
      setToken(data.login);
      navigate('/admin');
    },
  });

  return (
    <main className="flex items-center justify-center h-screen bg-ui-background">
      <div className="max-w-sm w-full bg-black bg-opacity-20 p-5 space-y-3">
        <div>
          <input
            className="bg-white text-black px-3 py-2 shadow-md w-full"
            type="text"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Email"
          />
        </div>
        <div>
          <input
            className="bg-white text-black px-3 py-2 shadow-md w-full"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Password"
          />
        </div>

        {error && <p className="text-red-700">{error}</p>}

        <Button loading={loading} onClick={login}>
          Login
        </Button>
      </div>
    </main>
  );
}
