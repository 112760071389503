import { useLocalStorage } from '@uidotdev/usehooks';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export const TOKEN_STORAGE_KEY = 'panorama_token';

export function useToken(redirectIfMissing = true) {
  const navigate = useNavigate();

  const [value, setValue] = useLocalStorage<string | null>(
    TOKEN_STORAGE_KEY,
    null,
  );

  useEffect(() => {
    if (!value && redirectIfMissing) {
      navigate('/admin/auth');
    }
  }, [value, redirectIfMissing, navigate]);

  return [value, setValue] as const;
}
