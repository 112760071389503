import { useState } from 'react';
import AdminPage from '../components/AdminPage';
import Button from '../components/Button';
import TextInput from '../components/input/TextInput';
import List from '../components/List';
import Modal from '../components/Modal';
import { useCreateClientMutation } from './mutations/createClientMutation';
import { useGetClientsQuery } from './queries/getClientsQuery';

export function AdminClientsView() {
  const [newClientName, setNewClientName] = useState('');
  const [showCreateModal, setShowCreateModal] = useState(false);

  const { data: clients, refetch } = useGetClientsQuery();
  const [createClient, { loading: createClientLoading }] =
    useCreateClientMutation({
      variables: {
        input: {
          name: newClientName,
        },
      },
      onCompleted: (data) => {
        setNewClientName('');
        setShowCreateModal(false);

        return refetch();
      },
    });

  if (!clients) {
    return null;
  }

  return (
    <AdminPage
      title="Clients"
      actions={
        <Button onClick={() => setShowCreateModal(true)}>New Client</Button>
      }
    >
      <List
        items={clients.clients}
        render={(client) => <div>{client.name}</div>}
        link={(client) => `/admin/clients/${client.id}`}
      />

      {showCreateModal && (
        <Modal
          heading="Create New Client"
          onClose={() => setShowCreateModal(false)}
        >
          <div className="space-y-2">
            <TextInput
              placeholder="Name"
              value={newClientName}
              onChange={setNewClientName}
            />

            <Button onClick={createClient} loading={createClientLoading}>
              Create
            </Button>
          </div>
        </Modal>
      )}
    </AdminPage>
  );
}
