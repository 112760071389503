import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { AnswerValue } from '../../../types';
import Button from '../components/Button';
import Container from '../components/Container';
import Header from '../components/Header';
import Controls from './components/Controls';
import Page from './components/Page';
import { QuizContext } from './context';
import { useSubmitQuizMutation } from './mutations/submitQuizMutation';
import { useGetQuizQuery } from './queries/getQuizQuery';
import { parseQuizToken } from './support';

type Params = {
  readonly token: string;
};

export function SurveyView() {
  const navigate = useNavigate();

  const { token } = useParams<Params>();
  const [id, respondentType] = parseQuizToken(token!);

  const [answers, setAnswers] = useState<Record<string, AnswerValue>>({});
  const [currentPage, setCurrentPage] = useState(-1);

  const { data } = useGetQuizQuery({
    variables: {
      id,
      respondentType,
    },
    onCompleted: (data) => {
      // Initialize answers with empty values for each question key.
      setAnswers(
        data.quiz.questions.reduce(
          (data, question) => ({
            ...data,
            [question.key]: ['TEXT', 'RANK'].includes(question.type) ? '' : [],
          }),
          {},
        ),
      );
    },
  });

  const [submitQuiz, { loading }] = useSubmitQuizMutation({
    variables: {
      id,
      input: {
        respondentType,
        answers: Object.entries(answers).map(([questionKey, value]) => ({
          questionKey,
          value,
        })),
      },
    },
    onCompleted: () => navigate('/thanks'),
  });

  if (!data || Object.keys(answers).length === 0) {
    return null;
  }

  if (data.quiz.closed) {
    return (
      <section className="bg-ui-background min-h-screen text-white flex items-center justify-center">
        <div>This survey is closed.</div>
      </section>
    );
  }

  return (
    <QuizContext.Provider value={{ quiz: data.quiz, answers, setAnswers }}>
      <main className="h-screen flex flex-col bg-ui-background text-white">
        <Header />

        <div className="flex-grow py-10 md:py-20">
          <Container className="mb-10">
            <h2 className="text-2xl font-thin">{data.quiz.title}</h2>
          </Container>

          {currentPage === -1 && (
            <Page
              body={data.quiz.introduction}
              questions={[]}
              allQuestionKeys={[]}
            />
          )}

          {data.quiz.pages.map((page, index) => {
            const questions = page.questionKeys
              .map(
                (key) => data.quiz.questions.find((q) => q.key === key) ?? null,
              )
              .filter((question) => question !== null);

            if (currentPage !== index) {
              return null;
            }

            return (
              <Page
                key={page.id}
                body={page.body}
                questions={questions}
                allQuestionKeys={data.quiz.pages
                  .map((page) => page.questionKeys)
                  .flat()}
              />
            );
          })}
        </div>
        <div className="flex-shrink-0 border-t border-white border-opacity-10 py-5">
          <Container>
            {currentPage < 0 && (
              <div className="flex justify-end">
                <Button onClick={() => setCurrentPage(0)}>Start</Button>
              </div>
            )}

            {currentPage >= 0 && (
              <Controls
                loading={loading}
                currentPage={currentPage}
                totalPages={data.quiz.pages.length}
                onPrev={() => setCurrentPage((prev) => Math.max(0, prev - 1))}
                onNext={() =>
                  setCurrentPage((prev) =>
                    Math.min(data.quiz.pages.length - 1, prev + 1),
                  )
                }
                onSubmit={() => submitQuiz()}
              />
            )}
          </Container>
        </div>
      </main>
    </QuizContext.Provider>
  );
}
