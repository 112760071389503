import { gql, MutationHookOptions, useMutation } from '@apollo/client';
import { AnswerValue, RespondentType } from '../../../../types';

const MUTATION = gql`
  mutation submitQuiz($id: ID!, $input: SubmitQuizInput!) {
    submitQuiz(id: $id, input: $input) {
      id
    }
  }
`;

export type Variables = {
  readonly id: string;
  readonly input: {
    readonly respondentType: RespondentType;
    readonly answers: readonly {
      readonly questionKey: string;
      readonly value: AnswerValue;
    }[];
  };
};

export type Response = {
  readonly submitQuiz: {
    readonly id: string;
  };
};

export const useSubmitQuizMutation = (
  options: MutationHookOptions<Response, Variables>,
) => useMutation<Response, Variables>(MUTATION, options);
