import Header from './components/Header';

export default function Thanks() {
  return (
    <div className="min-h-screen flex flex-col bg-ui-background text-white">
      <div className="flex-shrink-0">
        <Header />
      </div>
      <div className="flex flex-grow items-center justify-center">
        <div>Thank you for taking the survey!</div>
      </div>
    </div>
  );
}
