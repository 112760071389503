import { gql, MutationHookOptions, useMutation } from '@apollo/client';

const MUTATION = gql`
  mutation closeQuiz($id: ID!) {
    closeQuiz(id: $id) {
      id
      closed
    }
  }
`;

export type Variables = {
  readonly id: string;
};

export type Response = {
  readonly closeQuiz: {
    readonly id: string;
    readonly closed: string | null;
  };
};

export const useCloseQuizMutation = (
  options: MutationHookOptions<Response, Variables> = {},
) => useMutation<Response, Variables>(MUTATION, options);
