import clsx from 'clsx';
import { useMemo } from 'react';
import ReactMarkdown from 'react-markdown';
import MultiInput from '../../components/inputs/MultiInput';
import OrderInput from '../../components/inputs/OrderInput';
import RankInput from '../../components/inputs/RankInput';
import TextInput from '../../components/inputs/TextInput';
import { useAnswer } from '../hooks/useAnswer';
import { useAnswers } from '../hooks/useAnswers';
import { useQuestions } from '../hooks/useQuestions';
import { Response } from '../queries/getQuizQuery';

type Props = {
  readonly number: number;
  readonly question: Response['quiz']['questions'][number];
};

export default function Question({ number, question }: Props) {
  const questions = useQuestions();
  const answers = useAnswers();
  const [answer, setAnswer] = useAnswer(question.key);

  const options = useMemo(() => {
    switch (question.type) {
      case 'ORDER': {
        const selected = question.references
          .map((key) => (Array.isArray(answers[key]) ? answers[key] : []))
          .flat();

        const options = questions
          .filter((entry) => question.references.includes(entry.key))
          .map((entry) => entry.options ?? [])
          .flat();

        return question.subtype === 'INVERTED'
          ? options.filter((option) => !selected.includes(option.value))
          : options.filter((option) => selected.includes(option.value));
      }
      default:
        return question.options ?? [];
    }
  }, [question, questions, answers]);

  return (
    <section
      className={clsx({
        'space-y-1': question.type === 'TEXT',
        'space-y-8': question.type !== 'TEXT',
      })}
    >
      <div className="flex items-start space-x-3">
        <span className="leading-7">{number}.</span>
        <div className="prose prose-invert max-w-none">
          <ReactMarkdown>{question.text.replace(/\\n/g, '  \n')}</ReactMarkdown>
        </div>
      </div>

      <div>
        {question.type === 'TEXT' && (
          <TextInput value={answer.toString()} onChange={setAnswer} />
        )}
        {question.type === 'MULTI' && (
          <MultiInput
            value={Array.isArray(answer) ? answer : [answer]}
            onChange={setAnswer}
            options={options}
          />
        )}
        {question.type === 'RANK' && (
          <RankInput
            value={answer.toString()}
            onChange={setAnswer}
            options={options}
          />
        )}
        {question.type === 'ORDER' && (
          <OrderInput
            value={Array.isArray(answer) ? answer : [answer]}
            onChange={setAnswer}
            options={options}
          />
        )}
      </div>
    </section>
  );
}
