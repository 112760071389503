import { PropsWithChildren, ReactNode } from 'react';
import Heading from './Heading';

type Props = PropsWithChildren<{
  readonly title: string;
  readonly actions?: ReactNode;
}>;

export default function AdminPage({ title, children, actions }: Props) {
  return (
    <div className="w-full max-w-screen-md mx-auto py-5">
      <Heading level={1} className="mb-5" text={title} actions={actions} />

      <div>
        <section className="space-y-5">{children}</section>
      </div>
    </div>
  );
}
