import clsx from 'clsx';
import { PropsWithChildren } from 'react';

type Props = PropsWithChildren & {
  readonly disabled?: boolean;
  readonly loading?: boolean;
  readonly onClick: () => void;
};

export default function Button({
  disabled = false,
  loading = false,
  children,
  onClick,
}: Props) {
  return (
    <button
      disabled={disabled || loading}
      className={clsx(
        'bg-primary font-bold py-2 px-6 rounded-sm disabled:cursor-not-allowed',
        {
          'opacity-30 text-white': disabled || loading,
          'hover:bg-white text-primary-dark': !disabled && !loading,
        },
      )}
      onClick={onClick}
    >
      {children}
    </button>
  );
}
