import { createBrowserRouter, redirect } from 'react-router-dom';
import AdminClientsView from '../views/admin/AdminClientsView';
import AdminClientView from '../views/admin/AdminClientView';
import AdminSurveysView from '../views/admin/AdminSurveysView';
import AdminSurveyView from '../views/admin/AdminSurveyView';
import SurveyResponse from '../views/admin/AdminSurveyView/components/SurveyResponse';
import AdminView from '../views/admin/AdminView';
import AuthView from '../views/admin/AuthView';
import SurveyView from '../views/public/SurveyView';
import Thanks from '../views/public/Thanks';

export const router = createBrowserRouter([
  {
    path: '/',
    loader: () => redirect('https://cultureinstitute.com.au'),
  },
  {
    path: '/survey/:token',
    element: <SurveyView />,
  },
  {
    path: '/thanks',
    element: <Thanks />,
  },
  {
    path: '/admin',
    element: <AdminView />,
    errorElement: <div>Not found.</div>,
    children: [
      {
        index: true,
        loader: () => redirect('/admin/clients'),
      },
      {
        path: '/admin/clients',
        element: <AdminClientsView />,
      },
      {
        path: '/admin/clients/:id',
        element: <AdminClientView />,
      },
      {
        path: '/admin/surveys',
        element: <AdminSurveysView />,
      },
      {
        path: '/admin/surveys/:id',
        element: <AdminSurveyView />,
        children: [
          {
            path: '/admin/surveys/:id/:responseId',
            element: <SurveyResponse />,
          },
        ],
      },
    ],
  },
  {
    path: '/admin/auth',
    element: <AuthView />,
  },
]);
