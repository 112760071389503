import { RespondentType } from '../../../types';

/**
 * Parses the quiz token into the quiz id and the respondent type.
 *
 * @param value Base64 encoded quiz token.
 */
export function parseQuizToken(value: string): [string, RespondentType] {
  const [id, respondentType] = atob(value).split(':');

  return [id, respondentType as RespondentType];
}
