import { useState } from 'react';
import { useParams } from 'react-router-dom';
import AdminPage from '../components/AdminPage';
import Button from '../components/Button';
import TextInput from '../components/input/TextInput';
import List from '../components/List';
import Modal from '../components/Modal';
import { useCreateQuizMutation } from './mutations/createQuizMutation';
import { useGetClientQuery } from './queries/getClientQuery';

type Params = {
  readonly id: string;
};

export function AdminClientView() {
  const [newSurveyTitle, setNewSurveyTitle] = useState('');
  const [newSurveyIntroduction, setNewSurveyIntroduction] = useState('');
  const [newSurveyTemplateId, setNewSurveyTemplateId] = useState(
    // Todo: Hard-coded for now, but will want to make this dynamic eventually.
    // Doesn't add much immediate value though, so leaving it for now.
    '678c72be-32a3-47cd-970b-69c9c4ca05b6',
  );
  const [showCreateSurveyModal, setShowCreateSurveyModal] = useState(false);

  const { id } = useParams<Params>();
  const { data, refetch } = useGetClientQuery({ variables: { id: id! } });

  const [createQuiz, { loading: createQuizLoading }] = useCreateQuizMutation({
    variables: {
      input: {
        title: newSurveyTitle,
        introduction: newSurveyIntroduction,
        client: id!,
        template: newSurveyTemplateId,
      },
    },
    onCompleted: (data) => {
      setNewSurveyTitle('');
      setNewSurveyIntroduction('');
      setShowCreateSurveyModal(false);

      return refetch();
    },
  });

  if (!data) {
    return null;
  }

  return (
    <AdminPage
      title={`Clients - ${data.client.name}`}
      actions={
        <Button onClick={() => setShowCreateSurveyModal(true)}>
          Create Survey
        </Button>
      }
    >
      <div>
        <List
          items={data.quizzes}
          render={(quiz) => (
            <div>
              {quiz.title}{' '}
              {quiz.closed && (
                <span className="text-red-500 text-sm">(Closed)</span>
              )}
            </div>
          )}
          link={(quiz) => `/admin/surveys/${quiz.id}`}
        />
      </div>

      {showCreateSurveyModal && (
        <Modal
          heading="Create New Survey"
          onClose={() => setShowCreateSurveyModal(false)}
        >
          <div className="space-y-2">
            <TextInput
              placeholder="Title"
              value={newSurveyTitle}
              onChange={setNewSurveyTitle}
            />
            <TextInput
              placeholder="Introduction"
              value={newSurveyIntroduction}
              onChange={setNewSurveyIntroduction}
            />

            <Button onClick={createQuiz} loading={createQuizLoading}>
              Create
            </Button>
          </div>
        </Modal>
      )}
    </AdminPage>
  );
}
