import { faCheck } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx';

type Props = {
  readonly checked: boolean;
  readonly onClick: () => void;
};

export default function Checkbox({ checked, onClick }: Props) {
  return (
    <button
      className={clsx(
        'w-6 h-6 flex items-center justify-center border-2 rounded-sm',
        {
          'border-transparent bg-white text-black': checked,
          'border-white border-opacity-50 text-white': !checked,
        },
      )}
      onClick={onClick}
    >
      <FontAwesomeIcon
        className={clsx({
          'opacity-100': checked,
          'opacity-0': !checked,
        })}
        icon={faCheck}
      />
    </button>
  );
}
