import { gql, QueryHookOptions, useQuery } from '@apollo/client';

const QUERY = gql`
  query getQuizResponse($id: ID!) {
    quizResponse(id: $id) {
      id
      created
      respondentType
      respondentName
      answers {
        id
        value
        question {
          key
          text
        }
      }
    }
  }
`;

export type Variables = {};

export type Response = {
  readonly quizResponse: {
    readonly id: string;
    readonly created: string;
    readonly respondentType: string;
    readonly respondentName: string | null;
    readonly answers: readonly {
      readonly id: string;
      readonly value: unknown;
      readonly question: {
        readonly key: string;
        readonly text: string;
      };
    }[];
  };
};

export const useGetQuizResponseQuery = (
  options?: QueryHookOptions<Response, Variables>,
) => useQuery<Response, Variables>(QUERY, options);
