import { faChevronRight, faWarning } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ReactNode } from 'react';
import { Link } from 'react-router-dom';

type Props<T> = {
  readonly items: readonly T[];
  readonly render: (item: T) => ReactNode;
  readonly link?: (item: T) => string;
  readonly placeholder?: ReactNode;
};

export default function List<T extends { readonly id: string }>({
  items,
  link,
  render,
  placeholder = 'There are no items',
}: Props<T>) {
  return (
    <div className="rounded-md bg-white shadow-md">
      {items.length > 0 ? (
        <ul>
          {items.map((item) => (
            <li
              className="flex items-center space-x-3 px-3 py-2 border-b border-gray-100 last:border-0"
              key={item.id}
            >
              <div className="flex-grow">{render(item)}</div>
              {link && (
                <div className="flex-shrink-0">
                  <Link
                    className="flex items-center text-sm shadow-sm space-x-2 bg-primary text-primary-dark px-3 py-1 rounded-sm hover:bg-black hover:text-white"
                    to={link(item)}
                  >
                    <span>View</span>
                    <FontAwesomeIcon size="xs" icon={faChevronRight} />
                  </Link>
                </div>
              )}
            </li>
          ))}
        </ul>
      ) : (
        <div className="px-3 py-2 flex items-center space-x-2">
          <FontAwesomeIcon icon={faWarning} />
          <p>{placeholder}</p>
        </div>
      )}
    </div>
  );
}
