import {
  faExternalLinkAlt,
  faHexagonExclamation,
} from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { format } from 'date-fns';
import { useMemo } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import AdminPage from '../components/AdminPage';
import Button from '../components/Button';
import Heading from '../components/Heading';
import List from '../components/List';
import { useConfirm } from '../hooks/usePrompt';
import { useToken } from '../hooks/useToken';
import SurveyOverview from './components/SurveyOverview';
import { useCloseQuizMutation } from './mutations/closeQuizMutation';
import { useReopenQuizMutation } from './mutations/reopenQuizMutation';
import { useGetQuizQuery } from './queries/getQuizQuery';

type Params = {
  readonly id: string;
};

export function AdminSurveyView() {
  const [token] = useToken();

  const { id } = useParams<Params>();
  const { data, refetch } = useGetQuizQuery({ variables: { id: id! } });

  const confirm = useConfirm();

  const [closeQuiz, { loading: closeQuizLoading }] = useCloseQuizMutation({
    variables: {
      id: id!,
    },
    onCompleted: () => refetch(),
  });

  const [reopenQuiz, { loading: reopenQuizLoading }] = useReopenQuizMutation({
    variables: {
      id: id!,
    },
    onCompleted: () => refetch(),
  });

  const reportLink = useMemo(() => {
    return `${process.env.REACT_APP_GRAPHQL_ENDPOINT}/report/${id}?token=${token}`;
  }, [id, token]);

  if (!data) {
    return null;
  }

  return (
    <AdminPage
      title={`Surveys - ${data.quiz.title}`}
      actions={
        data.quiz.closed ? (
          <Button
            onClick={() => confirm('Reopen the survey?', () => reopenQuiz())}
          >
            Reopen
          </Button>
        ) : (
          <Button
            onClick={() =>
              confirm('Close the survey? This will make it inaccessible.', () =>
                closeQuiz(),
              )
            }
          >
            Close
          </Button>
        )
      }
    >
      {data.quiz.closed && (
        <div className="text-red-600 flex items-center space-x-2">
          <FontAwesomeIcon icon={faHexagonExclamation} />
          <p>This survey is closed and not accessible for completion.</p>
        </div>
      )}

      <SurveyOverview survey={data.quiz} />

      <Heading
        level={2}
        text={`Responses (${data.quiz.responses.length})`}
        actions={
          <a
            target="_blank"
            className="flex items-center space-x-2 text-blue-600 hover:text-blue-900"
            href={reportLink}
          >
            <span>Download</span>
            <FontAwesomeIcon size="xs" icon={faExternalLinkAlt} />
          </a>
        }
      />

      <List
        placeholder="No responses yet"
        items={data.quiz.responses}
        render={(response) => (
          <div className="flex items-center leading-tight space-x-3">
            <p>{response.respondentName ?? 'Anonymous'}</p>
            <p className="text-gray-600">
              {response.respondentType} &ndash;{' '}
              {format(response.created, 'do MMM yyyy')}
            </p>
          </div>
        )}
        // link={(response) => `/admin/surveys/${data.quiz.id}/${response.id}`}
      />

      <Outlet />
    </AdminPage>
  );
}
