import { gql, QueryHookOptions, useQuery } from '@apollo/client';

const QUERY = gql`
  query getClient($id: ID!) {
    client(id: $id) {
      name
    }
    quizzes(clients: [$id]) {
      id
      title
      closed
    }
  }
`;

export type Variables = {
  readonly id: string;
};

export type Response = {
  readonly client: {
    readonly name: string;
  };
  readonly quizzes: readonly {
    readonly id: string;
    readonly title: string;
    readonly closed: string | null;
  }[];
};

export const useGetClientQuery = (
  options?: QueryHookOptions<Response, Variables>,
) => useQuery<Response, Variables>(QUERY, options);
