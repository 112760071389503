import { faTimes } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate, useParams } from 'react-router-dom';
import OverlayPortal from '../../../components/OverlayPortal';
import { useGetQuizResponseQuery } from './queries/getQuizResponseQuery';

type Params = {
  readonly id: string;
  readonly responseId: string;
};

export function SurveyResponse() {
  const { id, responseId } = useParams<Params>();
  const navigate = useNavigate();

  const { data } = useGetQuizResponseQuery({ variables: { id: responseId! } });

  if (!data) {
    return null;
  }

  return (
    <OverlayPortal>
      <div className="bg-black bg-opacity-30 fixed inset-0 z-20">
        <div className="absolute flex flex-col inset-0 left-auto w-full max-w-screen-sm bg-white shadow-lg">
          <button
            className="absolute top-5 right-5"
            onClick={() => navigate(`/admin/surveys/${id}`)}
          >
            <FontAwesomeIcon icon={faTimes} />
          </button>

          <div className="flex-shrink-0 p-5 border-b border-gray-200">
            {data.quizResponse.respondentType} &ndash;{' '}
            {data.quizResponse.respondentName}
          </div>

          <div className="p-5 overflow-y-auto flex-grow">
            <h4 className="font-bold mb-5">Answers</h4>

            <div className="space-y-5">
              {data.quizResponse.answers.map((answer) => (
                <div key={answer.id}>
                  <p className="mb-2">{answer.question.text}</p>
                  <pre className="text-sm bg-gray-100 rounded-sm p-5">
                    <code>{JSON.stringify(answer.value, null, 2)}</code>
                  </pre>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </OverlayPortal>
  );
}
