import { gql, QueryHookOptions, useQuery } from '@apollo/client';

const QUERY = gql`
  query getQuizzes {
    quizzes {
      id
      title
      closed
      client {
        id
        name
      }
    }
  }
`;

export type Variables = {};

export type Response = {
  readonly quizzes: readonly {
    readonly id: string;
    readonly title: string;
    readonly closed: string | null;
    readonly client: {
      readonly id: string;
      readonly name: string;
    };
  }[];
};

export const useGetQuizzesQuery = (
  options?: QueryHookOptions<Response, Variables>,
) => useQuery<Response, Variables>(QUERY, options);
