import { gql, QueryHookOptions, useQuery } from '@apollo/client';

const QUERY = gql`
  query getClients {
    clients {
      id
      name
    }
  }
`;

export type Variables = {};

export type Response = {
  readonly clients: readonly {
    readonly id: string;
    readonly name: string;
  }[];
};

export const useGetClientsQuery = (
  options?: QueryHookOptions<Response, Variables>,
) => useQuery<Response, Variables>(QUERY, options);
