import { Response } from '../../SurveyView/queries/getQuizQuery';
import Checkbox from './Checkbox';

type Props = {
  readonly value: readonly string[];
  readonly onChange: (value: string[]) => void;
  readonly options: Response['quiz']['questions'][number]['options'];
};

export default function MultiInput({ value, onChange, options }: Props) {
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 gap-x-5 gap-y-3">
      {options.map((option) => (
        <div
          key={option.value}
          className="flex items-center space-x-5 cursor-pointer"
          onClick={() => {
            onChange(
              value.includes(option.value)
                ? value.filter((entry) => entry !== option.value)
                : value.concat(option.value),
            );
          }}
        >
          <div className="flex-shrink-0">
            <Checkbox
              checked={value.includes(option.value)}
              onClick={() => {}}
            />
          </div>
          <div className="leading-tight">{option.text}</div>
        </div>
      ))}
    </div>
  );
}
