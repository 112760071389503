import { Outlet } from 'react-router-dom';
import Navigation from '../components/Navigation';
import { useToken } from '../hooks/useToken';

export function AdminView() {
  // This is whatforces authentication for all child pages, do not remove it.
  useToken();

  return (
    <div className="h-screen overflow-hidden flex bg-gray-100">
      <aside className="w-full max-w-52 flex-shrink-0">
        <Navigation />
      </aside>
      <main className="flex-grow overflow-auto p-5">
        <Outlet />
      </main>
    </div>
  );
}
