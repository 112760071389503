import { gql, QueryHookOptions, useQuery } from '@apollo/client';

const QUERY = gql`
  query getQuiz($id: ID!) {
    quiz(id: $id) {
      id
      title
      individualLink: link(respondentType: INDIVIDUAL)
      leaderLink: link(respondentType: LEADER)
      closed
      client {
        id
        name
      }
      responses {
        id
        created
        respondentType
        respondentName
      }
    }
  }
`;

export type Variables = {};

export type Response = {
  readonly quiz: {
    readonly id: string;
    readonly title: string;
    readonly individualLink: string;
    readonly leaderLink: string;
    readonly closed: string | null;
    readonly client: {
      readonly id: string;
      readonly name: string;
    };
    readonly responses: readonly {
      readonly id: string;
      readonly created: string;
      readonly respondentType: string;
      readonly respondentName: string | null;
    }[];
  };
};

export const useGetQuizQuery = (
  options?: QueryHookOptions<Response, Variables>,
) => useQuery<Response, Variables>(QUERY, options);
