import { gql, QueryHookOptions, useQuery } from '@apollo/client';
import {
  Option,
  QuestionSubtype,
  QuestionType,
  RespondentType,
} from '../../../../types';

const QUERY = gql`
  query getQuiz($id: ID!, $respondentType: RespondentType) {
    quiz(id: $id) {
      id
      introduction
      title
      closed
      questions {
        type
        subtype
        key
        text
        required
        options {
          text
          value
        }
        references
      }
      pages(respondentType: $respondentType) {
        id
        body
        questionKeys
        respondentTypes
      }
    }
  }
`;

export type Variables = {
  readonly id: string;
  readonly respondentType: RespondentType;
};

export type Response = {
  readonly quiz: {
    readonly id: string;
    readonly introduction: string;
    readonly title: string;
    readonly closed: string | null;
    readonly questions: readonly {
      readonly type: QuestionType;
      readonly subtype: QuestionSubtype | null;
      readonly key: string;
      readonly text: string;
      readonly required: number;
      readonly options: readonly Option[];
      readonly references: readonly string[];
    }[];
    readonly pages: readonly {
      readonly id: string;
      readonly body: string;
      readonly questionKeys: readonly string[];
      readonly respondentTypes: readonly RespondentType[];
    }[];
  };
};

export const useGetQuizQuery = (
  options: QueryHookOptions<Response, Variables>,
) => useQuery<Response, Variables>(QUERY, options);
