import { AnswerValue } from '../../../../types';
import { useQuizContext } from '../context';

export function useAnswer(key: string) {
  const ctx = useQuizContext();

  const answer = ctx.answers[key];

  return [
    answer,
    (value: AnswerValue) =>
      ctx.setAnswers({
        ...ctx.answers,
        [key]: value,
      }),
  ] as const;
}
