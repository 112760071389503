import AdminPage from '../components/AdminPage';
import List from '../components/List';
import { useGetQuizzesQuery } from './queries/getQuizzesQuery';

export function AdminSurveysView() {
  const { data } = useGetQuizzesQuery();

  if (!data) {
    return <p>Loading&hellip;</p>;
  }

  return (
    <AdminPage title="Open Surveys">
      <div>
        <List
          items={data.quizzes}
          render={(survey) => (
            <div className="leading-tight">
              <p>
                {survey.title}{' '}
                {survey.closed && (
                  <span className="text-red-500 text-sm">(Closed)</span>
                )}
              </p>
              <p>
                <small className="text-gray-600">{survey.client.name}</small>
              </p>
            </div>
          )}
          link={(quiz) => `/admin/surveys/${quiz.id}`}
        />
      </div>
    </AdminPage>
  );
}
